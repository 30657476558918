// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 576px) {
  .reward_reward__y86pM {
    margin-top: -70px;
  }
}
.reward_reward__y86pM .reward_title__tbBG4 {
  color: var(--txt);
  font-size: 24px;
  font-weight: 700;
}
.reward_reward__y86pM .reward_title__tbBG4 span {
  color: #e09200;
}
.reward_reward__y86pM .reward_title2__XUU7p {
  margin: 0 0 10px 0;
  text-align: center;
  color: #e09200;
}`, "",{"version":3,"sources":["webpack://./src/components/Reward/reward.module.scss"],"names":[],"mappings":"AACE;EADF;IAEI,iBAAA;EACF;AACF;AAAE;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AADI;EACE,cAAA;AAGN;AAAE;EACE,kBAAA;EACA,kBAAA;EACA,cAAA;AAEJ","sourcesContent":[".reward {\n  @media screen and (max-width: 576px) {\n    margin-top: -70px;\n  }\n  .title {\n    color: var(--txt);\n    font-size: 24px;\n    font-weight: 700;\n    span {\n      color: #e09200;\n    }\n  }\n  .title2 {\n    margin: 0 0 10px 0;\n    text-align: center;\n    color: #e09200;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reward": `reward_reward__y86pM`,
	"title": `reward_title__tbBG4`,
	"title2": `reward_title2__XUU7p`
};
export default ___CSS_LOADER_EXPORT___;

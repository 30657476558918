// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetPlatformStats = (address) => {
  const [treasurePoolPercentage, setTreasurePoolPercentage] = useState(null);
  const [ownershipPoolPercentage, setOwnershipPoolPercentage] = useState(null);
  const [totalInvestment, setTotalInvestment] = useState(0);

  const {
    data: treasurePercentage,
    isSuccess: istreasurePoolPercentageCompleted,
    refetch: refetchTreasurePoolPercentage,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "tdividentPayoutPercentage",
  });
  const {
    data: ownershipPercentage,
    isSuccess: isOwnershipPoolPercentageCompleted,
    refetch: refetchOwnershipPoolPercentage,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "odividentPayoutPercentage",
  });
  const {
    data: investment,
    isSuccess: isInvestmentCompleted,
    refetch: refetchInvestment,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "totalStakedAmount",
  });
  useEffect(() => {
    if (treasurePercentage) {
      let percentage = Number(treasurePercentage.toString());

      setTreasurePoolPercentage(percentage / 100);
    }
  }, [treasurePercentage]);
  useEffect(() => {
    if (ownershipPercentage) {
      let percentage = Number(ownershipPercentage.toString());

      setOwnershipPoolPercentage(percentage / 100);
    }
  }, [ownershipPercentage]);
  useEffect(() => {
    if (investment) {
      setTotalInvestment(
        Number(ethers.utils.formatUnits(investment || 0, "ether"))
      );
    }
  }, [investment]);

  return {
    treasurePoolPercentage,
    istreasurePoolPercentageCompleted,
    refetchTreasurePoolPercentage,
    ownershipPoolPercentage,
    isOwnershipPoolPercentageCompleted,
    refetchOwnershipPoolPercentage,
    investment: totalInvestment,
    isInvestmentCompleted,
    refetchInvestment,
  };
};

export default useGetPlatformStats;

// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetBlnc= (address) => {
  const [blnc, setBlnc] = useState(null);

  const {
    data,
    isSuccess,
    refetch,
  } = useContractRead({
    address: CONTRACT_INFO.token.address,
    abi: CONTRACT_INFO.token.abi,
    functionName: "balanceOf",
    args:[address],
    enabled:!!address
  });
  useEffect(() => {
    if (data) {
        setBlnc( Number(ethers.utils.formatUnits(data || 0, "ether")))
    }
  }, [data]);

  return {
    blnc,
    data,
    isSuccess,
    refetch
  };
};

export default useGetBlnc;

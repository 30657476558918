// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withdrawHistory_withdrawlHistory__fiZVn .withdrawHistory_listingTitle__MYFkP {
  color: #e09200;
}
.withdrawHistory_withdrawlHistory__fiZVn .withdrawHistory_field__Q1Cul {
  color: var(--txt);
  font-size: 14px;
  font-weight: 500;
}
.withdrawHistory_withdrawlHistory__fiZVn .withdrawHistory_copyIcon__Zu0C\\+ path {
  stroke: var(--txt);
}`, "",{"version":3,"sources":["webpack://./src/components/WithdrawHistory/withdrawHistory.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGI;EACE,kBAAA;AADN","sourcesContent":[".withdrawlHistory {\n  .listingTitle {\n    color: #e09200;\n  }\n  .field {\n    color: var(--txt);\n    font-size: 14px;\n    font-weight: 500;\n  }\n  .copyIcon {\n    path {\n      stroke: var(--txt);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdrawlHistory": `withdrawHistory_withdrawlHistory__fiZVn`,
	"listingTitle": `withdrawHistory_listingTitle__MYFkP`,
	"field": `withdrawHistory_field__Q1Cul`,
	"copyIcon": `withdrawHistory_copyIcon__Zu0C+`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { Modal } from "antd";
import Classes from "./confirmModal.module.scss";

function ConfirmModal({ isModalOpen, handleCancel }) {
  return (
    <Modal
      className={Classes.disableModal}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <div className={Classes.content}>
        <p>Are you sure? You want to Enable payout.</p>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button className={`${Classes.btn} ${Classes.reject}`}>No</button>
          <button className={`${Classes.btn} ${Classes.accept}`}>Yes</button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;

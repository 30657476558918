// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__qipFo {
  background-color: var(--sectionBg);
  color: var(--txt);
  padding: 30px 0;
}
.footer_footer__qipFo .footer_name__h6nTw {
  font-size: 20px;
  font-weight: 600;
  color: var(--txt);
}
.footer_footer__qipFo ul {
  margin: 0;
  list-style: none;
}
.footer_footer__qipFo ul li {
  margin-bottom: 20px;
}
.footer_footer__qipFo ul li .footer_link__j14\\+d {
  font-size: 14px;
  font-weight: 600;
  color: var(--txt);
}
.footer_footer__qipFo ul li .footer_link__j14\\+d:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/layout/LandingLayout/Footer/footer.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,iBAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,SAAA;EACA,gBAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAIR;AAHQ;EACI,YAAA;AAKZ","sourcesContent":[".footer {\n  background-color: var(--sectionBg);\n  color: var(--txt);\n  padding: 30px 0;\n  .name {\n    font-size: 20px;\n    font-weight: 600;\n    color: var(--txt);\n  }\n  ul {\n    margin: 0;\n    list-style: none;\n    li {\n      margin-bottom: 20px;\n      .link {\n        font-size: 14px;\n        font-weight: 600;\n        color: var(--txt);\n        &:hover {\n            opacity: 0.8;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__qipFo`,
	"name": `footer_name__h6nTw`,
	"link": `footer_link__j14+d`
};
export default ___CSS_LOADER_EXPORT___;

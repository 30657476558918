import React from "react";
import Classes from "./investors.module.scss";
import Bars from "../../assets/images/icons/bars.svg";
import UserImg from "../../assets/images/section/user.svg";

function InvestorList({ showHeader = true }) {
  const data = [1, 2, 3];
  return (
    <div className={Classes.mainInvestors}>
      {showHeader && (
        <div className={Classes.header}>
          <img src={Bars} alt="icon" />
          <p>Investors</p>
        </div>
      )}
      {data.map((i) => (
        <div className={Classes.list} key={i}>
          <img className={Classes.img} src={UserImg} alt="img" />
          <div>
            <div className="mb-2">
              <p className={Classes.name}>Buyer Name</p>
              <span className={Classes.userName}>@idName</span>
            </div>
            <div>
              <p className={Classes.desc}>Description :</p>
              <p className={Classes.detail}>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the when an unknown printer took.
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default InvestorList;

import React from "react";
import Classes from "./withdrawHistory.module.scss";
import { Tooltip } from "antd";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { formatLargeNumber } from "../../helpers/common";
function WithdrawalHistory({ data, titles }) {
  return (
    <div className={Classes.withdrawlHistory}>
      <div>
        <h2 className={Classes.listingTitle}>Withdraw History</h2>
        <div className="overflow-auto">
          <table> 
            <tr>
              {titles &&
                titles?.length > 0 &&
                titles?.map((title) => {
                  return <th>{title}</th>;
                })} 
            </tr>
            {/* <tr> */}
            {data &&
              data?.length > 0 &&
              data?.map((item) => {
                return (
                  <tr key={item?.txHash}>
                    <td>
                      <p className={Classes.field}>{item.name}</p>
                    </td>
                    <td>
                      <p className={Classes.field}>{`${formatLargeNumber(item.amount||0)} USDC`}</p>
                    </td>
                    <td>
                      <p className={Classes.field}>{item.walletAddress}</p>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <p className={Classes.field}>{item.txHash}</p>
                        <Tooltip title="copy">
                          <CopyToClipboard
                            text={item.txHashComplete}
                            onCopy={() => {
                              toast.info(
                                "Transaction Hash Copied!"
                              );
                            }}
                          >
                            <CopyIcon className={Classes.copyIcon} />
                          </CopyToClipboard>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}

            {/* <td>
                <p className={Classes.field}>12345</p>
              </td>
              <td>
                <p className={Classes.field}>0xc2...52d4</p>
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <p className={Classes.field}>0xc2...52d4</p>
                  <Tooltip title="copy">
                    <CopyIcon className={Classes.copyIcon} />
                  </Tooltip>
                </div>
              </td> */}
            {/* </tr> */}
          </table>
        </div>
      </div>
    </div>
  );
}

export default WithdrawalHistory;


import React from "react";
import Classes from "./investment.module.scss";
import { Col, Row } from "antd";
import PiechartGraph from "../PieChart";
import Leaf from "../../assets/images/icons/yelowLeaf.svg";
import WarImg from "../../assets/images/section/war.svg";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const data = [
  { name: "User Income", value: 30 },
  { name: "Total Income", value: 70 },
];

// const COLORS = ["#FEC036", "#B31017"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const value = index === 0 ? "30%" : "70%";
  const label = index === 0 ? "User Income" : "Total Income";

  return (
    <g>
      <text
        x={x}
        y={y - 6}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="24"
        fontWeight="700"
        className="value-label"
      >
        {value}
      </text>
      <text
        x={x}
        y={y + 14}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="14"
        fontWeight="300"
        className="name-label"
      >
        {label}
      </text>
    </g>
  );
};
function Investment() {
  return (
    <div className={Classes.investment}>
      <div className={Classes.content}>
        <div className="d-flex align-items-center gap-2 mb-4">
          <img src={Leaf} alt="icon" />
          <p className={Classes.title}>Investment</p>
        </div>
        <div className={Classes.card}>
          <Row>
            <Col md={12}>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <defs>
                    <linearGradient
                      id="colorUserIncome"
                      x1="0"
                      y1="0"
                      x2="1"
                      y2="1"
                    >
                      <stop offset="0" stopColor="#FEC036" />
                      <stop offset="100%" stopColor="#C37A00" />
                    </linearGradient>
                    <linearGradient
                      id="colorTotalIncome"
                      x1="0"
                      y1="0"
                      x2="1"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#B31017" />
                      <stop offset="100%" stopColor="#A21016" />
                    </linearGradient>
                  </defs>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    strokeWidth={0}
                  >
                    <Cell key={`cell-0`} fill="url(#colorUserIncome)" />
                    <Cell key={`cell-1`} fill="url(#colorTotalIncome)" />
                  </Pie>
                  {/* <Tooltip /> */}
                </PieChart>
              </ResponsiveContainer>
              <PiechartGraph />
            </Col>
            <Col md={12}>
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img height={250} src={WarImg} alt="img" />
                <div className="text-center py-4">
                  <span className={Classes.txt}>Total Investment</span>
                  <h1 className={Classes.price}>$34,986</h1>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-4 my-3">
                  <div className="d-flex flex-column gap-4">
                    <div className={Classes.graphInfo}>
                      <div
                        className={`${Classes.box} ${Classes.yellowBox}`}
                      ></div>
                      <span>Drip Marble Run</span>
                    </div>
                    <div className={Classes.graphInfo}>
                      <div className={`${Classes.box} ${Classes.redBox}`}></div>

                      <span>Liquid Warriors</span>
                    </div>
                    <div className={Classes.graphInfo}>
                      <div
                        className={`${Classes.box} ${Classes.darkGrey}`}
                      ></div>
                      <span>Warrior Rush</span>
                    </div>
                    <div className={Classes.graphInfo}>
                      <div
                        className={`${Classes.box} ${Classes.lightGrey}`}
                      ></div>
                      <span>Plinko Fortune</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Investment;

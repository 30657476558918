import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Classes from "./landing.module.scss";
import ContentBlock from "./ContentBlock";
import sectionImg from "../../assets/images/section/section1.svg";
import sectionImg2 from "../../assets/images/section/section2.svg";
import sectionImg3 from "../../assets/images/section/section3.svg";
import { getAllProjects } from "../../api/services/projects";
import SocketContext from "../../context/socket";
const Landing = () => {
  const [projects, setProjects] = useState(null);
  const socket = useContext(SocketContext);
  const fetchProjects = async () => {
    const res = await getAllProjects();
    setProjects(res.data.data);
  };
  useEffect(() => {
  
    fetchProjects();
  }, []);
   useEffect(() => {
    if (socket ) {
      socket?.on("ProjectStatusUpdated", () => {
        fetchProjects()
      });
      return () => {
        socket?.off("ProjectStatusUpdated", () => {});
      };
    }
  }, [socket]);

  return (
    <div className={Classes.landing}>
      <div className={`${Classes.content} px-4`}>
        <Row gutter={[30, 30]}>
          {projects &&
            projects?.length > 0 &&
            projects.map((project) => {
              return (
                <Col lg={12} md={24}>
                    <ContentBlock
                      img={project?.projectImage}
                      name={project?.name}
                      desc={project?.description || ""}
                      link={`Go to ${project?.name}` || ""}
                      route={project?.projectLink}
                    />
                </Col>
              );
            })}

        </Row>
      </div>
    </div>
  );
};

export default Landing;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacyPolicy_PrivacyPolicy__v2wES .privacyPolicy_content__MFbpf {
  color: var(--txt);
  font-size: 16px;
  line-height: 32px;
  padding: 20px 0;
}
.privacyPolicy_PrivacyPolicy__v2wES .privacyPolicy_cancelBtn__DDYVP {
  background-color: #b31017;
  border: 1px solid #b31017;
  padding: 10px 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}
.privacyPolicy_PrivacyPolicy__v2wES .privacyPolicy_agreeBtn__5IZDU {
  background-color: #05af5a;
  border: 1px solid #05af5a;
  padding: 10px 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/models/PrivacyPolicy/privacyPolicy.module.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAEE;EACE,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AAAJ;AAEE;EACE,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AAAJ","sourcesContent":[".PrivacyPolicy {\n  .content {\n    color: var(--txt);\n    font-size: 16px;\n    line-height: 32px;\n    padding: 20px 0;\n  }\n  .cancelBtn {\n    background-color: #b31017;\n    border: 1px solid #b31017;\n    padding: 10px 30px;\n    border-radius: 5px;\n    color: #fff;\n    font-size: 16px;\n  }\n  .agreeBtn {\n    background-color: #05af5a;\n    border: 1px solid #05af5a;\n    padding: 10px 30px;\n    border-radius: 5px;\n    color: #fff;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrivacyPolicy": `privacyPolicy_PrivacyPolicy__v2wES`,
	"content": `privacyPolicy_content__MFbpf`,
	"cancelBtn": `privacyPolicy_cancelBtn__DDYVP`,
	"agreeBtn": `privacyPolicy_agreeBtn__5IZDU`
};
export default ___CSS_LOADER_EXPORT___;

import api from "../index";

const getAllProjects = () => {
  try {
    return api.get(`/project`);
  } catch (err) {
    console.log(err, "error=>");
  }
};

export { getAllProjects };

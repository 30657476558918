import React from "react";
import { Col, Modal, Row } from "antd";
import Classes from "./connectWallet.module.scss";
import MetaMask from "../../assets/images/icons/metamask.svg";
import CoinBase from "../../assets/images/icons/coinbase.svg";
import WalletConnect from "../../assets/images/icons/walletConnect.svg";
import Glow from "../../assets/images/icons/glow.svg";
import Phantom from "../../assets/images/icons/phantom.svg";

function ConnectWallet({ isModalOpen, handleCancel }) {
  const data = [
    {
      img: MetaMask,
      title: "MetaMask",
    },
    {
      img: CoinBase,
      title: "Coinbase Wallet",
    },
    {
      img: WalletConnect,
      title: "WalletConnect",
    },
    {
      img: Glow,
      title: "Glow",
    },
    {
      img: Phantom,
      title: "Phantom",
    },
  ];
  return (
    <Modal
      className={Classes.connectModal}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <>
        <h1 className={Classes.title}>Connect Your Wallet</h1>
        <p className={Classes.desc}>Select what network and wallet you want to connect </p>
        <Row gutter={[20, 20]} className='my-4'>
          {data?.map((item, i) => (
            <Col key={i} md={8}>
              <div className={Classes.walletBox}>
                <img src={item.img} alt="img" />
                <p className={Classes.subtitle}>{item.title}</p>
              </div>
            </Col>
          ))}
        </Row>
        <button className={Classes.connectBtn}>Connect</button>
      </>
    </Modal>
  );
}

export default ConnectWallet;

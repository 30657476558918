import React from "react";
import Classes from "./investors.module.scss";
import Bars from "../../assets/images/icons/yellowBars.svg";
import InvestorList from "../InvestorsList";

function Investors() {
  return (
    <div className={Classes.investment}>
      <div className={Classes.content}>
        <div className="d-flex align-items-center gap-2 mb-4">
          <img src={Bars} alt="icon" />
          <p className={Classes.title}>Investors</p>
        </div>
        <InvestorList showHeader={false} />
      </div>
    </div>
  );
}

export default Investors;

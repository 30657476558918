import api from "../index";

const getPlatformFundsByProject = (id) => {
  try {
    return api.get(`/platform-fund/project-funds/${id}`);
  } catch (err) {
    console.log(err, "error=>");
  }
};

export { getPlatformFundsByProject };

import React, { useState } from "react";
import Classes from "./footer.module.scss";
import { Col, Row } from "antd";
import Logo from "../../../assets/images/section/logo.svg";
import { Link } from "react-router-dom";
import PrivacyPolicy from "../../../models/PrivacyPolicy";
import Terms from "../../../models/Terms";

function Footer() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTerms, setIsTerms] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const show = () => {
    setIsTerms(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleAgree = () => {
    setIsTerms(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const cancel = () => {
    setIsTerms(false);
  };
  return (
    <>
      <footer className={`${Classes.footer} px-4`}>
        <Row>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Link to="/" className="d-flex align-items-center gap-2 mb-3">
              <img height={60} src={Logo} alt="logo" />
              <span className={Classes.name}>The Warriors DAO</span>
            </Link>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <ul className="pt-4">
              <li className="mb-4">
                <Link className={Classes.link} onClick={showModal}>
                  Privary Policy
                </Link>
              </li>
              <li>
                <Link className={Classes.link} onClick={show}>
                  Terms of Service
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </footer>
      <PrivacyPolicy
        isModalOpen={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
      <Terms
        isModalOpen={isTerms}
        handleCancel={cancel}
        handleOk={handleAgree}
      />
    </>
  );
}

export default Footer;

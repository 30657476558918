// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentBlock_contentBlock__mZGoj {
  background-color: var(--cardBg);
  border-radius: 5px;
}
.contentBlock_contentBlock__mZGoj img {
  height: 290px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
@media screen and (max-width: 576px) {
  .contentBlock_contentBlock__mZGoj img {
    height: 200px;
  }
}
.contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq {
  padding: 10px;
}
.contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--txt);
  margin: 0 0 10px 0;
}
@media screen and (max-width: 576px) {
  .contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq h3 {
    font-size: 16px;
  }
}
.contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq p {
  color: var(--txt);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 26px;
}
@media screen and (max-width: 576px) {
  .contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq p {
    font-size: 12px;
  }
}
.contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq .contentBlock_actionBtn__kZH-c {
  width: 100%;
  background-color: #b31017;
  display: block;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
@media screen and (max-width: 576px) {
  .contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq .contentBlock_actionBtn__kZH-c {
    font-size: 12px;
  }
}
.contentBlock_contentBlock__mZGoj .contentBlock_content__Me5jq .contentBlock_actionBtn__kZH-c:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/Landing/ContentBlock/contentBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AADI;EALF;IAMI,aAAA;EAIJ;AACF;AAFE;EACE,aAAA;AAIJ;AAHI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAKN;AAJM;EALF;IAMI,eAAA;EAON;AACF;AALI;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAON;AANM;EANF;IAOI,eAAA;EASN;AACF;AAPI;EACE,WAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AASN;AARM;EAVF;IAWI,eAAA;EAWN;AACF;AAVM;EACE,YAAA;AAYR","sourcesContent":[".contentBlock {\n  background-color: var(--cardBg);\n  border-radius: 5px;\n  img {\n    height: 290px;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 5px;\n    @media screen and (max-width: 576px) {\n      height: 200px;\n    }\n  }\n  .content {\n    padding: 10px;\n    h3 {\n      font-size: 20px;\n      font-weight: 500;\n      color: var(--txt);\n      margin: 0 0 10px 0;\n      @media screen and (max-width: 576px) {\n        font-size: 16px;\n      }\n    }\n    p {\n      color: var(--txt);\n      font-size: 14px;\n      font-weight: 400;\n      margin-bottom: 15px;\n      line-height: 26px;\n      @media screen and (max-width: 576px) {\n        font-size: 12px;\n      }\n    }\n    .actionBtn {\n      width: 100%;\n      background-color: #b31017;\n      display: block;\n      border-radius: 5px;\n      text-align: center;\n      padding: 15px;\n      color: #fff;\n      font-size: 14px;\n      font-weight: 600;\n      @media screen and (max-width: 576px) {\n        font-size: 12px;\n      }\n      &:hover {\n        opacity: 0.8;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBlock": `contentBlock_contentBlock__mZGoj`,
	"content": `contentBlock_content__Me5jq`,
	"actionBtn": `contentBlock_actionBtn__kZH-c`
};
export default ___CSS_LOADER_EXPORT___;

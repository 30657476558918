import { io } from "socket.io-client";
// import { ENV } from '../config';
let socket = null;

const connectSocket = () => {
  try {
    if (!socket) {
      const socketInstance = new io(process.env.REACT_APP_API_BASE_URL);
      // const socketInstance = new io(ENV?.serverUrl || 'http://localhost:7000');

      return socketInstance;
    }
  } catch (err) {
    console.log(err, "err");
  }
};

export default { connectSocket, socket };

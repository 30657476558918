// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connectWallet_connectModal__ufwfG {
  width: 720px !important;
}
.connectWallet_connectModal__ufwfG .connectWallet_title__6qZhv {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: var(--txt);
}
.connectWallet_connectModal__ufwfG .connectWallet_desc__hiRtp {
  color: #7e7e8b;
  font-size: 14px;
  font-weight: 400;
}
.connectWallet_connectModal__ufwfG .connectWallet_walletBox__6go14 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--sectionBg);
  padding: 20px 0;
  border-radius: 10px;
  height: 110px;
  border: 3px solid var(--sectionBg);
}
.connectWallet_connectModal__ufwfG .connectWallet_walletBox__6go14 img {
  transition: transform 0.3s ease;
}
.connectWallet_connectModal__ufwfG .connectWallet_walletBox__6go14:hover {
  border: 3px solid var(--border400);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: none;
}
.connectWallet_connectModal__ufwfG .connectWallet_walletBox__6go14:hover img {
  transform: scale(1.2);
}
.connectWallet_connectModal__ufwfG .connectWallet_walletBox__6go14 .connectWallet_subtitle__PI-KK {
  font-size: 16px;
  font-weight: 400;
  color: var(--txt);
  margin-top: 5px;
}
.connectWallet_connectModal__ufwfG .connectWallet_connectBtn__O2mU4 {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #b31017;
  border: 1px solid #b31017;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}
.connectWallet_connectModal__ufwfG .ant-modal {
  min-width: 720px !important;
}
.connectWallet_connectModal__ufwfG .anticon.anticon-close.ant-modal-close-icon svg {
  color: var(--txt);
}`, "",{"version":3,"sources":["webpack://./src/models/ConnectWallet/connectWallet.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,SAAA;EACA,iBAAA;AAEJ;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kCAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,kCAAA;AAEJ;AADI;EACE,+BAAA;AAGN;AADI;EACE,kCAAA;EACA,qDAAA;EACA,YAAA;AAGN;AADI;EACE,qBAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAGN;AAAE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,qBAAA;AAEJ;AACI;EACE,2BAAA;AACN;AAEM;EACE,iBAAA;AAAR","sourcesContent":[".connectModal {\n  width: 720px !important;\n  .title {\n    font-size: 24px;\n    font-weight: 700;\n    margin: 0;\n    color: var(--txt);\n  }\n  .desc {\n    color: #7e7e8b;\n    font-size: 14px;\n    font-weight: 400;\n  }\n  .walletBox {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    background-color: var(--sectionBg);\n    padding: 20px 0;\n    border-radius: 10px;\n    height: 110px;\n    border: 3px solid var(--sectionBg);\n    img {\n      transition: transform 0.3s ease;\n    }\n    &:hover {\n      border: 3px solid var(--border400);\n      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n      cursor: none;\n    }\n    &:hover img {\n      transform: scale(1.2);\n    }\n    .subtitle {\n      font-size: 16px;\n      font-weight: 400;\n      color: var(--txt);\n      margin-top: 5px;\n    }\n  }\n  .connectBtn {\n    width: 100%;\n    height: 50px;\n    border-radius: 10px;\n    background-color: #b31017;\n    border: 1px solid #b31017;\n    font-size: 18px;\n    font-weight: 500;\n    color: #fff;\n    margin: 10px 0 15px 0;\n  }\n  :global {\n    .ant-modal {\n      min-width: 720px !important;\n    }\n    .anticon.anticon-close.ant-modal-close-icon {\n      svg {\n        color: var(--txt);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectModal": `connectWallet_connectModal__ufwfG`,
	"title": `connectWallet_title__6qZhv`,
	"desc": `connectWallet_desc__hiRtp`,
	"walletBox": `connectWallet_walletBox__6go14`,
	"subtitle": `connectWallet_subtitle__PI-KK`,
	"connectBtn": `connectWallet_connectBtn__O2mU4`
};
export default ___CSS_LOADER_EXPORT___;

import api from "../index";
const getUserStats = () => {
  try {
    return api.get("user/test/stats");
  } catch (err) {
    console.log(err, "error=>");
  }
};
const fetchUser = () => {
  try {
    return api.get("user");
  } catch (err) {
    console.log(err, "error=>");
  }
};
const updateUser = (payload) => {
  try {
    return api.patch("user", payload);
  } catch (err) {
    console.log(err, "error=>");
  }
};
export { getUserStats, fetchUser, updateUser };

import React, { useEffect, useState } from "react";
import Classes from "./mainDashboard.module.scss";
import Overview from "../Overview";
import Investment from "./Investment";
import { Col, Row } from "antd";
import DividendPayouts from "./DividendPayouts";
import { getPlatformStats } from "../../api/services/dashboardStats";
import useGetPlatformStats from "../../contract/hooks/stake/useGetPlatformInfo";
import useGetPoolAmount from "../../contract/hooks/stake/useGetPoolAmount";
import * as moment from 'moment-timezone';
import { formatLargeNumber } from "../../helpers/common";
function MainDashboard() {
  const [stats, setStats] = useState(null);
  const [pagination,setPagination]=useState({
    page:1,
    limit:10
  })
  const subtitles = [
    "Total DAO Investment",
    "Treasury Pool Balance",
    "OwnerShip Pool Balance",
  ];
  useEffect(() => {
    const getStats = async () => {
      const res = await getPlatformStats({...pagination});
      setStats(res?.data);
    };
    getStats();
  }, []);
  const { treasurePoolPercentage, ownershipPoolPercentage, investment } =
    useGetPlatformStats();
  const { ownerShipPool, treasurePool } = useGetPoolAmount();

  return (
    <div className={Classes.main}>
      <Overview
        subtitles={subtitles}
        stats={{
          title1: formatLargeNumber(investment),
          title2: formatLargeNumber(treasurePool),
          title3: formatLargeNumber(ownerShipPool),
        }}
      />
      <Row gutter={[5, 5]}>
        <Col lg={14} md={24} sm={24} xs={24}>
          <Investment stats={{ ...stats, investment }} />
        </Col>
        <Col lg={10} md={24} sm={24} xs={24}>
          <DividendPayouts
            stats={{ ownershipPoolPercentage, treasurePoolPercentage }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default MainDashboard;

import api from "../index";
const validateUser = (address) => {
  try {
    return api.post(`/user/validate/wallet/${address}`);
  } catch (err) {
    console.log(err, "error=>");
  }
};

const validateSignature = (address, signature) => {
  try {
    return api.post(`/user/verify-signature/${address}`, { signature });
  } catch (err) {
    console.log(err, "error=>");
  }
};

export { validateUser, validateSignature };

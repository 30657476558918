// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investors_mainInvestors__eMjZL {
  position: relative;
  z-index: 9;
  margin: 0 15px 0 0;
  background-color: var(--cardBg);
  border-radius: 12px;
  margin-bottom: 22px;
  padding: 25px;
}
.investors_mainInvestors__eMjZL .investors_header__bB7dV {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(102, 103, 120, 0.5019607843);
  padding-bottom: 20px;
}
.investors_mainInvestors__eMjZL .investors_header__bB7dV p {
  font-size: 12px;
  font-weight: 400;
  color: #e09200;
}
.investors_mainInvestors__eMjZL .investors_list__JLHOk {
  display: flex;
  gap: 13px;
  padding: 20px 0;
  border-bottom: 1px solid #252730;
}
.investors_mainInvestors__eMjZL .investors_list__JLHOk img {
  align-self: flex-start;
  height: 38px;
  width: 38px;
  border-radius: 100%;
}
.investors_mainInvestors__eMjZL .investors_list__JLHOk .investors_name__Iy-zL {
  font-size: 14px;
  font-weight: 600;
  color: var(--txt);
  margin-bottom: 3px;
}
.investors_mainInvestors__eMjZL .investors_list__JLHOk .investors_userName__fYyrB {
  font-size: 12px;
  font-weight: 400;
  color: #7E7E8B;
}
.investors_mainInvestors__eMjZL .investors_list__JLHOk .investors_desc__Uabsi {
  font-size: 14px;
  font-weight: 400;
  color: #7E7E8B;
  margin-bottom: 5px;
}
.investors_mainInvestors__eMjZL .investors_list__JLHOk .investors_detail__A6jmg {
  color: #7E7E8B;
  font-size: 11px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/InvestorsList/investors.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,+BAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,0DAAA;EACA,oBAAA;AAEN;AADM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAGR;AAAI;EACI,aAAA;EACA,SAAA;EACA,eAAA;EACA,gCAAA;AAER;AADQ;EACI,sBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAGZ;AADQ;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAGZ;AADQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAGZ;AADQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAGZ;AADQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AAGZ","sourcesContent":[".mainInvestors {\n    position: relative;\n    z-index: 9;\n    margin: 0 15px 0 0;\n    background-color: var(--cardBg);\n    border-radius: 12px;\n    margin-bottom: 22px;\n    padding: 25px;\n    .header {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n      border-bottom: 1px solid #66677880;\n      padding-bottom: 20px;\n      p {\n        font-size: 12px;\n        font-weight: 400;\n        color: #e09200;\n      }\n    }\n    .list {\n        display: flex;\n        gap: 13px;\n        padding: 20px 0;\n        border-bottom: 1px solid #252730;\n        img {\n            align-self: flex-start;\n            height: 38px;\n            width: 38px;\n            border-radius: 100%;\n        }\n        .name {\n            font-size: 14px;\n            font-weight: 600;\n            color: var(--txt);\n            margin-bottom: 3px;\n        }\n        .userName {\n            font-size: 12px;\n            font-weight: 400;\n            color: #7E7E8B;\n        }\n        .desc {\n            font-size: 14px;\n            font-weight: 400;\n            color: #7E7E8B;\n            margin-bottom: 5px;\n        }\n        .detail {\n            color: #7E7E8B;\n            font-size: 11px;\n            font-weight: 400;\n        }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainInvestors": `investors_mainInvestors__eMjZL`,
	"header": `investors_header__bB7dV`,
	"list": `investors_list__JLHOk`,
	"name": `investors_name__Iy-zL`,
	"userName": `investors_userName__fYyrB`,
	"desc": `investors_desc__Uabsi`,
	"detail": `investors_detail__A6jmg`
};
export default ___CSS_LOADER_EXPORT___;

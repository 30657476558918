// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/section/bgLight.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investors_investment__-BI\\+m {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  background-attachment: fixed;
  height: 100vh;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
}
.investors_investment__-BI\\+m::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlayBG);
  opacity: 0.85;
  z-index: 1;
}
.investors_investment__-BI\\+m .investors_content__U\\+fGM {
  position: relative;
  z-index: 9;
  padding: 0 10px;
}
.investors_investment__-BI\\+m .investors_content__U\\+fGM .investors_title__Enu\\+m {
  color: #e09200;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.investors_investment__-BI\\+m .investors_content__U\\+fGM .investors_card__SGnnA {
  background-color: var(--cardBg);
  border-radius: 12px;
  padding: 20px 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Investors/investors.module.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;EACA,4BAAA;EACA,sBAAA;EACA,+BAAA;EACA,2BAAA;EAEA,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AACI;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;EACA,aAAA;EACA,UAAA;AACN;AACI;EACE,kBAAA;EACA,UAAA;EACA,eAAA;AACN;AAAM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AAER;AAAM;EACE,+BAAA;EACA,mBAAA;EACA,kBAAA;AAER","sourcesContent":[".investment {\n    background: url(\"../../assets/images/section/bgLight.svg\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: top center;\n    background-color: var(--bg);\n    // height: 100%;\n    background-attachment: fixed;\n    height: 100vh;\n    line-height: normal;\n    padding: 110px 20px 30px;\n    color: var(--txt);\n    position: relative;\n    &::before {\n      content: \"\";\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      background-color: var(--overlayBG);\n      opacity: 0.85;\n      z-index: 1;\n    }\n    .content {\n      position: relative;\n      z-index: 9;\n      padding: 0 10px;\n      .title {\n        color: #e09200;\n        font-size: 16px;\n        font-weight: 400;\n        margin: 0;\n      }\n      .card {\n        background-color: var(--cardBg);\n        border-radius: 12px;\n        padding: 20px 50px;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"investment": `investors_investment__-BI+m`,
	"content": `investors_content__U+fGM`,
	"title": `investors_title__Enu+m`,
	"card": `investors_card__SGnnA`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Classes from "./contentBlock.module.scss";
import { Link } from "react-router-dom";

const ContentBlock = (props) => {
  return (
    <div className={Classes.contentBlock}>
      <img src={props.img} alt="img" />
      <div className={Classes.content}>
        <h3>{props.name}</h3>
        <p>{props.desc}</p>
        <Link className={Classes.actionBtn} to={props?.route || "/"}>
          {props.link}
        </Link>
      </div>
    </div>
  );
};

export default ContentBlock;

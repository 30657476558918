import React, { useState } from "react";
import { Modal } from "antd";
import Classes from "./profilemodal.module.scss";
import User from "../../assets/images/icons/userYellow.svg";
import Edit from "../../assets/images/icons/edit.svg";
import ProfileImg from "../../assets/images/section/userImg.svg";
import Profile from "../../components/Profile";
import ProfileCard from "../../components/ProfileCard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSignMessage, useAccount, useDisconnect } from "wagmi";
import { useDispatch } from "react-redux";
import {
  setUserData,
  setWalletAddress,
} from "../../store/_actions/auth_action";
function ProfileModal({ isModalOpen, handleCancel }) {
  const [profileImage, setProfileImage] = useState(ProfileImg);
  const navigate=useNavigate()
  const { disconnect } = useDisconnect();
  const dispatch= useDispatch()
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const logout = () => {
    dispatch(setWalletAddress(null));
    dispatch(setUserData(null));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    disconnect();
    navigate("/login");
  };
  return (
    <Modal
      className={Classes.profileModal}
      footer={null}
      open={isModalOpen}
      onClose={()=>toast.info("Please fill out at least the required fields to continue")}

      // onCancel={handleCancel}
    >
      <ProfileCard isModal={true} logout={logout}/>
    </Modal>
  );
}

export default ProfileModal;

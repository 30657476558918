import "core-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { store } from "./store/index";
// import HandleError from './_errors/error';
import ReactGA from "react-ga4";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import { Web3Modal } from "./context/web3Modal";
const root = ReactDOM.createRoot(
  document.getElementById("root") 
);
ReactGA.initialize("G-SF6NEW6780");
root.render(
  <div>
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer autoClose={3000}/>
        </BrowserRouter>
      </Provider>
    </div>
  </div>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

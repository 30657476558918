// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetPoolAmount = () => {
  const [ownerShipPool, setOwnerShipPool] = useState(0);
  const [treasurePool, setTreasurePool] = useState(0);

  const {
    data: OPool,
    isSuccess: isOwnerShipPoolCompleted,
    refetch: refetchOwnershipPool,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "ownerShipPoolAmount",
  });
  const {
    data: TPool,
    isSuccess: isTreasurePoolCompleted,
    refetch: refetchTreasurePool,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "treasuryPoolAmount",
  });

  useEffect(() => {
    if (OPool) {
      setOwnerShipPool(Number(ethers.utils.formatUnits(OPool || 0, "ether")));
    }
  }, [OPool]);
  useEffect(() => {
    if (TPool) {
      setTreasurePool(Number(ethers.utils.formatUnits(TPool || 0, "ether")));
    }
  }, [TPool]);

  const refetch = () => {
    refetchOwnershipPool();
    refetchTreasurePool();
  };

  return {
    ownerShipPool,
    treasurePool,
    refetch,
  };
};

export default useGetPoolAmount;

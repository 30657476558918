import React, { useEffect, useState,useContext } from "react";
import Classes from "./header.module.scss";
import { Button, Input, Tooltip } from "antd";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import WalletImg from "../../../assets/images/icons/wallet.svg";
import ConnectWallet from "../../../models/ConnectWallet";
import { ReactComponent as Moon } from "../../../assets/images/icons/moon.svg";
import { ReactComponent as Sun } from "../../../assets/images/icons/sun.svg";
import UserImg from "../../../assets/images/section/user.svg";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useSignMessage, useAccount, useDisconnect } from "wagmi";
import { validateUser, validateSignature } from "../../../api/services/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserData,
  setWalletAddress,
} from "../../../store/_actions/auth_action";
import { useNavigate } from "react-router-dom";
import SocketContext from "../../../context/socket";
import { fetchUser } from "../../../api/services/user";
const HeaderComponent = ({ collapsed, toggleCollapsed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (tooltipVisible) {
      timer = setTimeout(() => {
        setTooltipVisible(false);
      }, 1000); // Tooltip will disappear after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [tooltipVisible]);
  const { open } = useWeb3Modal();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  const {
    data: signMessageData,
    error,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();
  const handleCancel = () => setIsModalOpen(false);
  const { address } = useAccount();
  const socket = useContext(SocketContext);
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  useEffect(() => {
    // On component mount, set initial theme based on darkMode state
    if (darkMode) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.add("light-theme");
    }

    return () => {
      // Cleanup function to remove class on unmount
      document.body.classList.remove("dark-theme");
      document.body.classList.remove("light-theme");
    };
  }, [darkMode]);
  useEffect(() => {
    if (address) {
      handleWalletConnect();
    }
    const loggedInAddress = localStorage.getItem("walletAddress");
    if (
      (loggedInAddress &&
        address &&
        loggedInAddress.toLocaleUpperCase() !== address.toLocaleUpperCase()) ||
      (loggedInAddress && !address)
    ) {
      logout();
    }
  }, [address]);
  useEffect(() => {
    if (!address || address !== user?.walletAddress) {
      logout();
    }
  }, [address]);

  useEffect(() => {
    const handleValidateSignature = async () => {
      const res = await validateSignature(address, signMessageData);
      if (res?.data?.data?.accessToken) {
        localStorage.setItem("accessToken", res.data.data.accessToken);
        dispatch(setWalletAddress(address));
      }
    };
    const token = localStorage.getItem("accessToken");
    if (signMessageData && !token) {
      handleValidateSignature();
    }
  }, [signMessageData]);
  useEffect(() => {
    if (error) {
      dispatch(setWalletAddress(null));
      disconnect();
    }
  }, [error]);

  const handleClick = () => {
    // Toggle the darkMode state
    setDarkMode((prevMode) => !prevMode);
  };
  const refetchUserInfo = async () => {
    const res = await fetchUser();
    dispatch(setUserData(res?.data));
  };

  const handleWalletConnect = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      const res = await validateUser(address);
      const user = res.data.data;
      signMessage({
        message: `I am signing my one-time nonce: ${user?.nonce}`,
      });
    } else {
      dispatch(setWalletAddress(address));
    }
  };
  const logout = () => {
    dispatch(setWalletAddress(null));
    dispatch(setUserData(null));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    disconnect();
    navigate("/login");
  };
  useEffect(() => {
    if (socket && user?._id) {
      socket.emit("join", `${user?._id}`);
      socket?.on("UserStatus", (data) => {
        logout()

      });
      return () => {
        socket.off("UserStatus"); // Ensure you turn off the right event
      };
    }
  }, [socket, user]);
  useEffect(()=>{
    if(user&&localStorage.getItem('accessToken')){
      refetchUserInfo()
    }
   
  },[])


  const { name, email, profileImage } = user || {};
  return (
    <>
      <div
        className={`${Classes.header} d-flex align-items-center justify-content-md-between justify-content-center gap-2 w-100`}
      >
        <div className="d-flex align-items-center gap-2">
          <Button
            className={Classes.toggleBtn}
            icon={collapsed ? <BiChevronRight /> : <BiChevronLeft />}
            onClick={toggleCollapsed}
          />
          {/* <Input classNames={Classes.input} placeholder="Search..." /> */}
        </div>
        <div className="d-flex align-items-center gap-1 pe-3 flex-sm-row flex-column">
          {name && (
            <div className={`${Classes.user} d-flex align-items-center gap-2`}>
              <img
                className={Classes.userImg}
                src={profileImage || UserImg}
                alt="img"
              />
              <div className={Classes.userInfo}>
                <p className={`${Classes.name} mb-1`}>{name || ""}</p>
                <span>{email || ""}</span>
              </div>
            </div>
          )}

          <div
            className={`${Classes.walletOuter} d-flex align-items-center gap-md-4 gap-1 ps-md-1 ps-0`}
          >
            <button className={Classes.toggleMode} onClick={handleClick}>
              {darkMode ? (
                <Tooltip
                  placement="bottom"
                  title="Dark Mode"
                  trigger="click"
                  visible={tooltipVisible}
                  onVisibleChange={(visible) => setTooltipVisible(visible)}
                >
                  <Moon onClick={() => setTooltipVisible(true)} />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="bottom"
                  title="Light Mode"
                  trigger="click"
                  visible={tooltipVisible}
                  onVisibleChange={(visible) => setTooltipVisible(visible)}
                >
                  <Sun
                    className={Classes.sunIcon}
                    onClick={() => setTooltipVisible(true)}
                  />
                </Tooltip>
              )}
            </button>
            <button
              onClick={() => {
                if (address) {
                  return;
                }
                open({ view: "Networks" });
              }}
              className={`${Classes.walletBtn} ${Classes.connectBtn} d-flex align-items-center gap-2`}
            >
              <img src={WalletImg} alt="icon" />
              <span>
                {address
                  ? address?.slice(0, 4) + "..." + address?.slice(-4)
                  : "Connect Wallet"}
              </span>
            </button>
            {address && (
              <button
                cl
                className={`${Classes.walletBtn} d-flex align-items-center w-100`}
                onClick={() => logout()}
              >
                Disconnect
              </button>
            )}
          </div>
        </div>
      </div>
      <ConnectWallet isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  );
};

export default HeaderComponent;

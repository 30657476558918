import React, { useEffect, useState } from "react";
import Classes from "./header.module.scss";
import { Tooltip } from "antd";
import Logo from "../../../assets/images/section/logo.svg";
import WalletImg from "../../../assets/images/icons/wallet.svg";
import DashboardIcon from "../../../assets/images/icons/dashboardIcon.svg";
import ConnectWallet from "../../../models/ConnectWallet";
import { ReactComponent as Moon } from "../../../assets/images/icons/moon.svg";
import { ReactComponent as Sun } from "../../../assets/images/icons/sun.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const HeaderComponent = ({ collapsed, toggleCollapsed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);
  const navigate = useNavigate();
  useEffect(() => {
    // On component mount, set initial theme based on darkMode state
    if (darkMode) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.add("light-theme");
    }

    return () => {
      // Cleanup function to remove class on unmount
      document.body.classList.remove("dark-theme");
      document.body.classList.remove("light-theme");
    };
  }, [darkMode]);

  const handleClick = () => {
    // Toggle the darkMode state
    setDarkMode((prevMode) => !prevMode);
  };
  const isUserLoggedIn=localStorage.getItem('accessToken')
  return (
    <>
      <header
        className={`${Classes.landingHeader} d-flex align-items-center justify-content-between gap-2 w-100 px-4`}
      >
        <Link
          to="/"
          className="d-flex align-items-center gap-2 text-decoration-none"
        >
          <img className={Classes.logo} src={Logo} alt="logo" />
          <span className={Classes.logoName}>The Warriors DAO</span>
        </Link>
        <div className="d-flex align-items-center gap-2 pe-3">
          <div
            className={`${Classes.walletOuter} d-flex align-items-center gap-md-3 gap-2 ps-4`}
          >
            <Link
              to={isUserLoggedIn?"/dashboard":"/login"}
              className={`${Classes.loginBtn} d-flex align-items-center gap-2`}
            >
            {isUserLoggedIn?"Dashboard" :" Sign in"}
            </Link>
            {/* <Link to='/dashboard'
              className={`${Classes.dashboardBtn} d-flex align-items-center gap-2`}
            >
              <img src={DashboardIcon} alt="icon" />
              <span>Dashboard</span>
            </Link>
            <button
              onClick={showModal}
              className={`${Classes.walletBtn} d-flex align-items-center gap-2`}
            >
              <img src={WalletImg} alt="icon" />
              <span>Connect Wallet</span>
            </button> */}
            <button className={Classes.toggleMode} onClick={handleClick}>
              {darkMode ? (
                <Tooltip placement="bottom" title="Dark Mode">
                  <Moon />
                </Tooltip>
              ) : (
                <Tooltip placement="bottom" title="Light Mode">
                  <Sun className={Classes.sunIcon} />
                </Tooltip>
              )}
            </button>
          </div>
        </div>
      </header>
      <ConnectWallet isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  );
};

export default HeaderComponent;

import React from "react";
import Classes from "./dividendpayouts.module.scss";
import Bars from "../../../assets/images/icons/bars.svg";
import { Col, Row } from "antd";

function DividendPayouts({ stats }) {
  const { treasurePoolPercentage, ownershipPoolPercentage } = stats || {};
  return (
    <div className={Classes.payouts}>
      <div className={Classes.header}>
        <img src={Bars} alt="icon" />
        <p>Dividend Payout</p>
      </div>
      <Row
        className={`${Classes.section} d-flex align-items-center justify-content-center py-2`}
      >
        <Col lg={8} md={24} xs={24}>
          <h4 className={Classes.title}>Treasury Pool</h4>
        </Col>
        <Col lg={16} md={24} xs={24}>
          <div className={Classes.payoutVector}>
            <p>{treasurePoolPercentage?.toFixed(2) || "-"}%</p>
          </div>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center py-3">
        <Col lg={8} md={24} xs={24}>
          <h4 className={Classes.title}>OwnerShip Pool</h4>
        </Col>
        <Col lg={16} md={24} xs={24}>
          <div className={Classes.payoutVector}>
            <p>{ownershipPoolPercentage?.toFixed(2) || "-"}%</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DividendPayouts;

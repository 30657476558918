import React, { useEffect, useState } from "react";
import Classes from "./profile.module.scss";
import User from "../../assets/images/icons/userYellow.svg";
import ProfileCard from "../ProfileCard";

function Profile() {
  return (
    <div className={Classes.profile}>
      <div className={Classes.content}>
        <div className="d-flex align-items-center gap-2 mb-4 ps-3">
          <img src={User} alt="icon" />
          <p className={Classes.title}>Profile</p>
        </div>
        <ProfileCard />
      </div>
    </div>
  );
}

export default Profile;

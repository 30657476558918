// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetPayout = (address) => {
  const [totalPayout, setTotalPayout] = useState(null);
  const [totalWithdrawal, setTotalWithdrawal] = useState(null);

  const [treasurePoolpayout, setTreasurePoolPayout] = useState(null);
  const [ownershipPoolPayout, setOwnershipPayout] = useState(null);
  const [totalStakedAmount,setTotalStakedAmount]=useState(null)
  const {
    data: payout,
    isSuccess: isPayoutCompleted,
    refetch: refetchPayout,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "userRegistered",
    args:[address],
    enabled:!!address
  });
  useEffect(() => {
    if (payout) {
        setTotalPayout(Number(ethers.utils.formatUnits(payout[1] || 0, "ether")))
        setTotalWithdrawal(Number(ethers.utils.formatUnits(payout[2] || 0, "ether")))
       setTotalStakedAmount( Number(ethers.utils.formatUnits(payout[5] || 0, "ether")))
       setTreasurePoolPayout(Number(ethers.utils.formatUnits(payout[3] || 0, "ether")))
       setOwnershipPayout(Number(ethers.utils.formatUnits(payout[4] || 0, "ether")))
    }
  }, [payout]);

  return {
    totalPayout,
    treasurePoolpayout,
    ownershipPoolPayout,
    totalStakedAmountByUser:totalStakedAmount,
    isPayoutCompleted,
    totalWithdrawal,
    refetchPayout
  };
};

export default useGetPayout;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/section/bgLight.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDashboard_main__9GSRk {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100%;
  background-attachment: fixed;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
}
.mainDashboard_main__9GSRk::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlayBG);
  opacity: 0.85;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/MainDashboard/mainDashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,mDAAA;EACA,4BAAA;EACA,sBAAA;EACA,+BAAA;EACA,2BAAA;EACA,YAAA;EACA,4BAAA;EAEA,mBAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;AAAF;AACE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;EACA,aAAA;EACA,UAAA;AACJ","sourcesContent":[".main {\n  background: url(\"../../assets/images/section/bgLight.svg\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top center;\n  background-color: var(--bg);\n  height: 100%;\n  background-attachment: fixed;\n  // height: 100vh;\n  line-height: normal;\n  padding: 110px 20px 30px;\n  color: var(--txt);\n  position: relative;\n  &::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: var(--overlayBG);\n    opacity: 0.85;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `mainDashboard_main__9GSRk`
};
export default ___CSS_LOADER_EXPORT___;

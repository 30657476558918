import React, { useState } from "react";
import { Modal } from "antd";
import Classes from "./privacyPolicy.module.scss";

function PrivacyPolicy({ isModalOpen, handleCancel, handleOk }) {
  return (
    <Modal
      className={Classes.PrivacyPolicy}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      title="Privacy Policy"
    >
      <p className={Classes.content}>
        We value your privacy and are committed to protecting
        your personal information. The data collected is, your Wallet Address,
        email address, and any other information you voluntarily provide in your
        emergency contact. This is solely for the purposes of communication and
        enhancing your website experience. This data may be used to improve the
        site, respond to your inquiries, or provide customer support. We do not
        sell, rent, or share personal information with any third parties.
      </p>
      <div className="d-flex justify-content-center align-items-centet gap-3 pt-4 pb-3">
        <button className={Classes.cancelBtn} onClick={handleCancel}>
          Cancel
        </button>
        <button className={Classes.agreeBtn} onClick={handleOk}>
          Agree
        </button>
      </div>
    </Modal>
  );
}

export default PrivacyPolicy;

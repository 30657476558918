import { toast } from "react-toastify";

export function setupRequestInterceptor(api) {
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export function setupResponseInterceptor(api) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Handle specific error scenarios
      console.log(error?.response,"error?.response=>")
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.message === "Forbidden: User is banned"
      ) {
        // Handle banned user scenario
        toast.error(error?.response?.data?.message, {
          duration: 5000,
        });
      } else if (error?.response?.status === 401&&window.location.pathname!=='/login') {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('walletAddress')
        window.location.replace('/login')
        return 
      } else {
        // Generic error handling
        error?.response?.data?.message &&
          toast.error(error?.response?.data?.message, {
            duration: 5000,
          });
      }
      return Promise.reject(error);
    }
  );
}

import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../LandingLayout/Header";
import Footer from "../LandingLayout/Footer";

const LandingLayout = (props) => (
  <div>
    <Header />
    <main>
      {props.children}
      {/* <Outlet /> */}
    </main>
    <Footer />
  </div>
);

export default LandingLayout;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reinvestHistory_reinvestHistory__SbR5k .reinvestHistory_listingTitle__eofzv {
  color: #e09200;
}
.reinvestHistory_reinvestHistory__SbR5k .reinvestHistory_field__59Cj3 {
  color: var(--txt);
  font-size: 14px;
  font-weight: 500;
}
.reinvestHistory_reinvestHistory__SbR5k .reinvestHistory_copyIcon__2twv3 path {
  stroke: var(--txt);
}`, "",{"version":3,"sources":["webpack://./src/components/ReinvestHistory/reinvestHistory.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGI;EACE,kBAAA;AADN","sourcesContent":[".reinvestHistory {\n  .listingTitle {\n    color: #e09200;\n  }\n  .field {\n    color: var(--txt);\n    font-size: 14px;\n    font-weight: 500;\n  }\n  .copyIcon {\n    path {\n      stroke: var(--txt);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reinvestHistory": `reinvestHistory_reinvestHistory__SbR5k`,
	"listingTitle": `reinvestHistory_listingTitle__eofzv`,
	"field": `reinvestHistory_field__59Cj3`,
	"copyIcon": `reinvestHistory_copyIcon__2twv3`
};
export default ___CSS_LOADER_EXPORT___;

// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const withdrawContractAddress = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useWithdraw = ({setWithdrawLoader}) => {
  // approve contract

  const {
    isError: isWithdrawSentError,
    data: withdrawSentTx,
    isLoading: isWithdrawTxInProgress,
    isSuccess: isWithdrawTxSent,
    write: withdraw,
    error: withdrawSentError
  } = useContractWrite({
    address: withdrawContractAddress,
    abi,
    functionName: "userWithdrawAmoount",
  });

  const {
    isLoading: isWithdrawWaiting,
    isSuccess: isWithdrawCompleted,
    isError: isWithdrawError,
    error: withdrawTxError,
    data: withdrawResp
  } = useWaitForTransaction({
    hash: withdrawSentTx?.hash,
  });

  useEffect(()=>{
    const error=withdrawSentError||withdrawTxError
    if(error){
      setWithdrawLoader(false)
      toast.error(extractErrorMessage(error?.message))
    }

  },[withdrawSentError,withdrawTxError])

  return {
    // withdraw operation
    isWithdrawSentError,
    withdrawSentTx,
    isWithdrawTxInProgress,
    isWithdrawTxSent,
    withdraw,
    isWithdrawCompleted,
    isWithdrawError,
    isWithdrawWaiting,
    withdrawSentError,
    withdrawTxError,
    withdrawResp
  };
};

export default useWithdraw;

import { SET_WALLET_ADDRESS,SET_USER_DATA,SET_USER_PROFILE_MODAL_STATUS } from "../constants";

const initialWalletState = {
  connectedAddress: "",
  user:null,
  isUserProfileModalOpen:false
};

export const AuthReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_WALLET_ADDRESS:
      return {
        ...state,
        connectedAddress:action.payload
      };

      case SET_USER_DATA:
        return {
          ...state,
          user:action.payload
        };

        case SET_USER_PROFILE_MODAL_STATUS:
          return {
            ...state,
            isUserProfileModalOpen:action.payload
          };

    default:
      return state;
  }
};

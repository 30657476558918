import api from "../index";
const getWithdrawalStats = () => {
  try {
    return api.get("/user/withdrawal/stats");
  } catch (err) {
    console.log(err, "error=>");
  }
};
const addWithdrawal = (data) => {
  try {
    return api.post("user/withdrawal", data);
  } catch (err) {
    console.log(err, "error=>");
  }
};
const getWithdrawalsByUser = (data) => {
  try {
    return api.get("user/withdrawal", data);
  } catch (err) {
    console.log(err, "error=>");
  }
};
export { getWithdrawalStats, addWithdrawal, getWithdrawalsByUser };

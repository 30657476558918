export const colors = [
    { color: "#7e7e8b" }, // neutral grey
    { color: "#FF000A" }, // bright red
    { color: "#a5a6b4" }, // soft grey
    { color: "#efb323" }, // gold-yellow
    { color: "#2E2E38" }, // dark grey (background match)
    { color: "#20202A" }, // even darker grey
    { color: "#D32F2F" }, // deeper red
    { color: "#F57C00" }, // warm orange
    { color: "#FFB300" }, // amber
    { color: "#FF5722" }, // strong orange
    { color: "#455A64" }, // slate grey (neutral)
    { color: "#C2185B" }, // pink-red
    { color: "#FF9800" }, // vivid orange
    { color: "#607D8B" }, // blue-grey (for subtle tones)
    { color: "#673AB7" }, // purple
    { color: "#FFEB3B" }, // bright yellow
    { color: "#BDBDBD" }, // light grey (soft contrast)
    { color: "#4CAF50" }, // green accent
    { color: "#03A9F4" }, // light blue
    { color: "#1E88E5" }, // deeper blue
    { color: "#9E9E9E" }, // mid-grey (neutral)
    { color: "#757575" }  // dark grey (contrast)
]; 
import React, { useRef, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Web3Modal } from "./context/web3Provider";
import Socket from "./helpers";
import { routes } from "./routes";
import PrivateRoute from "./privateRoute";
import SocketContext from "./context/socket";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "./api/services/user";
import { setUserData, setUserProfileModalStatus,setWalletAddress} from "./store/_actions/auth_action";
import { SET_USER_PROFILE_MODAL_STATUS } from "./store/constants";
function App() {
  const cursor = useRef(null);
  const [socket, setSocket] = useState(undefined);
  const user = useSelector((state) => state.auth.user);
  console.log(useSelector((state)=>console.log(state,"state=>")))
  const dispatch = useDispatch();
  const changePosition = (e) => {
    cursor.current.style.top = `${e.clientY}px`;
    cursor.current.style.left = `${e.clientX}px`;
  };
  useEffect(() => {
    if (!socket) {
      const connectedSocket = Socket.connectSocket();
      setSocket(connectedSocket);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);
 
  useEffect(()=>{
    console.log(user,!user?.name,"user==>")
    if(user){
      if(!user?.name){
        dispatch(setUserProfileModalStatus(true))
      }
      else{
        dispatch(setUserProfileModalStatus(false))
  
      }
    }

  },[user])

  return (
    <div className="app" onMouseMove={changePosition}>
      <div className="cursor-style" ref={cursor}></div>
      <Web3Modal>
        <SocketContext.Provider value={socket}>
          <Routes>
            {routes.map((route, i) => {
              return (
                <Route
                  path={route.path}
                  key={i}
                  element={
                    <PrivateRoute access={route.access}>
                      <route.layout >
                        <route.component />
                      </route.layout>
                    </PrivateRoute>
                  }
                />
              );
            })}
          </Routes>
        </SocketContext.Provider>
      </Web3Modal>
    </div>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-theme {
  --bg: #181923;
  --txt: #fff;
  --subTxt: #7e7e8b;
  --inputBg: #25273080;
  --overlayBG: #2A2C3C;
  --cardBg: rgba(0, 0, 0, 0.6);
  --desc: #7C8DB5;
  --border: #E6EDFF;
  --modalBg: #252630;
  --sectionBg: #181923;
  --border400: #666778;
  --scrollBg: #393d4b;
  --tooltipBg: #252730;
  --input: #1e202a;
  --btnGroup: #24252d;
  --cardBorder: #9697A0;
}
.light-theme {
  --bg: #F3F3F3;
  --txt: #000;
  --subTxt: #7e7e8b;
  --inputBg: #f5f5f5;
  --overlayBG: #e8e8e7;
  --cardBg: #F8F8F8;
  --desc: #000;
  --border: #000;
  --modalBg: #fff;
  --sectionBg: #f5f5f5;
  --border400: #ccc;
  --scrollBg: #f2f2f2;
  --tooltipBg: #dedcdc;
  --input: #f5f5f5;
  --btnGroup: #fff;
  --cardBorder: #000;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/_variables.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,oBAAoB;EACpB,4BAA4B;EAC5B,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,oBAAoB;EACpB,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".dark-theme {\n  --bg: #181923;\n  --txt: #fff;\n  --subTxt: #7e7e8b;\n  --inputBg: #25273080;\n  --overlayBG: #2A2C3C;\n  --cardBg: rgba(0, 0, 0, 0.6);\n  --desc: #7C8DB5;\n  --border: #E6EDFF;\n  --modalBg: #252630;\n  --sectionBg: #181923;\n  --border400: #666778;\n  --scrollBg: #393d4b;\n  --tooltipBg: #252730;\n  --input: #1e202a;\n  --btnGroup: #24252d;\n  --cardBorder: #9697A0;\n}\n.light-theme {\n  --bg: #F3F3F3;\n  --txt: #000;\n  --subTxt: #7e7e8b;\n  --inputBg: #f5f5f5;\n  --overlayBG: #e8e8e7;\n  --cardBg: #F8F8F8;\n  --desc: #000;\n  --border: #000;\n  --modalBg: #fff;\n  --sectionBg: #f5f5f5;\n  --border400: #ccc;\n  --scrollBg: #f2f2f2;\n  --tooltipBg: #dedcdc;\n  --input: #f5f5f5;\n  --btnGroup: #fff;\n  --cardBorder: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

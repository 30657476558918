import React, { useEffect, useState } from "react";
import Classes from "./overview.module.scss";
import { Col, Row } from "antd";
import Icon from "../../assets/images/icons/icon.svg";
import Icon2 from "../../assets/images/icons/icon2.svg";
import Icon3 from "../../assets/images/icons/icon3.svg";
import UpArrow from "../../assets/images/icons/up.svg";
import DownArrow from "../../assets/images/icons/down.svg";
import { useSelector } from "react-redux";

function Overview({ subtitles, stats,userDashbaord }) {
  const [formatedData, setFormatedData] = useState([
    {
      title: '-',
      icon: Icon,
      borderClass: Classes.border_right,
    },
    {
      title:  '-',
      icon: Icon2,
      borderClass: `${Classes.border_right}`,
    },
    { title:  '-', icon: Icon3, borderClass: "" },
  ]);
 const user= useSelector((state)=>state?.auth?.user)

  useEffect(() => {
    if (stats?.title1!==null&&stats?.title2!==null&&stats?.title3!==null) {
      let data = [
        {
          title: `${stats?.title1} USDC`,
          icon: Icon,
          borderClass: Classes.border_right,
        },
        {
          title: `${stats.title2} USDC`,
          icon: Icon2,
          borderClass: `${Classes.border_right}`,
        },
        { title:`${stats.title3} USDC`, icon: Icon3, borderClass: "" },
      ];
      setFormatedData(data);
    }
  }, [stats]);

  return (
    <div className={Classes.overview}>
      <Row gutter={[20, 20]}>
        {formatedData&&formatedData?.length>0&&formatedData.map((card, index) => (
          <Col lg={8} md={24} sm={24} xs={24} key={index}>
            <div className={`${Classes.card} ${card.borderClass}`}>
              <div className="d-flex align-items-center justify-content-between  gap-5 mb-md-3 mb-0">
                <div>
                  <h3 className={Classes.title}>{card?.title}</h3>
                  <p className={Classes.subtitle}>
                    {subtitles ? subtitles[index] : ""}
                  </p>
                </div>
                <img className={Classes.icon} src={card.icon} alt="icon" />
              </div>
              {card?.arrow && card?.desc1 && card?.desc2 && (
                <div className="d-flex align-items-center gap-2">
                  <img src={card.arrow} alt="icon" />
                  <div className="d-flex align-items-center gap-3">
                    <p className={Classes.desc}>{card.desc1}</p>
                    <p className={Classes.desc}>{card.desc2}</p>
                  </div>
                </div>
              )}
             
            </div>
          </Col>
        ))}
        
      </Row>
    </div>
  );
}

export default Overview;

import api from "../index";

const getAllSettings = () => {
  try {
    return api.get("/settings");
  } catch (err) {
    console.log(err, "error=>");
  }
};

export { getAllSettings };
